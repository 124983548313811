import type { FetchResponse } from 'ofetch'
import { storeToRefs } from 'pinia'
import { isClient, isFunction, isNumber, isObject } from '@qctsw/utils'
import { v4 as uuidv4 } from 'uuid'
import type { FetchResponseType, NitroFetchContext } from './types'
import { generateRequestKey } from './core'

import { useAuth } from '@/stores/auth'
import type { useAbort } from '@/server/request/abort'
import { useNotification } from '@/composables/useNotification'
import type { NuxtApp } from '#app'

type UUIDString = string
export class ApiInterceptor {
  localErrorStatus: number[]
  // errorStatus
  errorStatus: number[]
  updateStatus: number[]
  // 成功code
  successCode: number[]
  // 需要登录的code
  loginCode: number[]
  // 失败code
  errorCode: number[]
  // 需要展示提示的code
  bindPhoneCode: number[]
  // 是否全局错误
  isGlobalError: boolean

  nuxtAppMap = new Map<string, NuxtApp>()
  constructor() {
    this.localErrorStatus = []
    this.errorStatus = [400, 403, 404, 405, 500]
    this.updateStatus = [503, 560]
    // 201登录成功, 202退出登录成功
    this.successCode = [200, 201, 202]
    // 需要登录
    this.loginCode = [401, 402, 501, 502, 504]
    this.errorCode = [400, 403, 404, 405, 500]
    this.bindPhoneCode = [203]
    this.isGlobalError = false

    // this.autoRefresh = data.autoRefresh
  }

  protected getCurrentNuxtApp(key: UUIDString) {
    let nuxtApp: NuxtApp | null
    if (this.nuxtAppMap.has(key)) {
      nuxtApp = this.nuxtAppMap.get(key) as NuxtApp
    }
    else {
      try {
        nuxtApp = useNuxtApp()
        this.setCurrentNuxtApp(key, nuxtApp)
      }
      catch {
        nuxtApp = null
      }
    }
    return nuxtApp
  }

  protected setCurrentNuxtApp(key: UUIDString, nuxtApp: NuxtApp) {
    // 貌似这个会导致服务的内存占用过高
    this.nuxtAppMap.set(key, nuxtApp)
  }

  protected removeCurrentNuxtApp(key: UUIDString) {
    this.nuxtAppMap.delete(key)
  }

  protected setHeaderUUID(headers: Headers): UUIDString {
    const uuid = uuidv4()
    // @ts-expect-error @typescript-eslint/ban-ts-comment
    headers['X-Request-Id'] = uuid
    return uuid
  }

  protected getHeaderUUID(headers: Headers): UUIDString | undefined {
    if (!headers.has || !isFunction(headers.has))
      // @ts-expect-error @typescript-eslint/ban-ts-comment
      return headers['X-Request-Id'] || headers['x-request-id'] || ''
    else if (headers.has('X-Request-Id') || headers.has('x-request-id'))
      return headers.get('X-Request-Id') || headers.get('x-request-id') || ''
  }

  protected timeout(_ctx: NitroFetchContext): boolean {
    return Boolean(_ctx.options?.timeout && isNumber(_ctx.options?.timeout) && _ctx.options?.timeout > 0)
  }

  /**
   * 请求拦截器
   * @param _ctx NitroFetchContext
   */
  onRequest = async (_ctx: NitroFetchContext): Promise<void> => {
    // set uuid
    const uuid = this.setHeaderUUID(_ctx.options.headers as Headers)
    try {
      const nuxtApp = useNuxtApp()
      this.setCurrentNuxtApp(uuid, nuxtApp)
      // 处理重复请求
      if (nuxtApp && (_ctx.options?.setting?.repetition === 'abort' || this.timeout(_ctx))) {
        const { removePendingRequest, addPendingRequest } = nuxtApp.$useAbort as ReturnType<typeof useAbort>
        removePendingRequest(_ctx, 'abort')
        addPendingRequest(_ctx)
      }
    }
    catch (e) {
      console.error('onRequest', e)
    }

    // console.log('onRequest:', useNuxtApp())
    const { USER_TOKEN } = storeToRefs(useAuth())
    // 设置token
    if (USER_TOKEN.value)
      _ctx.options.headers = { Authorization: USER_TOKEN.value, ...(_ctx.options.headers || {}) }

    // 服务端请求设置客户端ip
    if (!isClient)
      _ctx.options.headers = this.setClientHeaders(_ctx.options.headers)
  }

  /**
   * 请求错误拦截
   * ❗不知道什么情况触发onRequestError
   * 已知触发条件👇：
   * 1.请求中断时会被触发
   * @param _ctx NitroFetchContext
   */
  onRequestError = async (_ctx: NitroFetchContext & { error: Error }): Promise<void> => {
    const uuid = this.getHeaderUUID(_ctx.options.headers as Headers)
    if (uuid) {
      const nuxtApp = this.getCurrentNuxtApp(uuid)
      this.removeCurrentNuxtApp(uuid)

      if (_ctx.error && _ctx.error?.name === 'AbortError' && nuxtApp) {
        // 如果是中止请求，则不自动重试
        _ctx.options.retry = 0
        try {
          const { abortPrePageKeys } = nuxtApp.$useAbort as ReturnType<typeof useAbort>
          // 如果是跳转页面时中止请求，则不提示
          const key = generateRequestKey(_ctx.request as string, _ctx.options)
          if (abortPrePageKeys.includes(key)) {
            _ctx.options.setting ? (_ctx.options.setting.noPrompts = true) : (_ctx.options.setting = { noPrompts: true })

            const index = abortPrePageKeys.indexOf(key)
            index > -1 && abortPrePageKeys.splice(index, 1)
          }
        }
        catch (error) {
          console.error('<<onRequestError error>>', error)
        }
      }
    }
    // 处理重复请求
    if (_ctx.error?.message?.match('Failed to fetch')) {
      const { noPrompts = false } = _ctx.options.setting || {}
      !noPrompts && useNotification.warning({
        title: '数据加载异常',
        content: '网络请求无法正常发送',
      })
    }

    console.error(_ctx.error.name, _ctx.error.message, _ctx.request)
    // && _ctx.options.timeout
    if (_ctx.error.name === 'AbortError' && _ctx.options.timeout && _ctx.error.message.includes('aborted'))
      this.timeoutErrorHandle(_ctx)
  }

  /**
   * 响应拦截器
   * @param _ctx NitroFetchContext
   */
  onResponse = async <R = FetchResponseType<unknown>>(_ctx: NitroFetchContext & { response: FetchResponse<R> }): Promise<unknown> => {
    const uuid = this.getHeaderUUID(_ctx.options.headers as Headers)
    const nuxtApp = uuid ? this.getCurrentNuxtApp(uuid) : null
    uuid && this.removeCurrentNuxtApp(uuid)

    if (nuxtApp && nuxtApp && (_ctx.options?.setting?.repetition === 'abort' || this.timeout(_ctx))) {
      const { removePendingRequest } = nuxtApp.$useAbort as ReturnType<typeof useAbort>
      removePendingRequest(_ctx, 'clear')
    }

    // 设置token
    this.setToken(_ctx.response.headers)
    const { status } = _ctx.response
    // 响应数据
    const responseData = _ctx.response._data as FetchResponseType<unknown>
    const { code, msg } = responseData
    if (this.localErrorStatus.includes(status) || this.localErrorStatus.includes(code))
      return this.localErrorHandle<R>(status, responseData, _ctx)

    if (this.updateStatus.includes(status) || this.updateStatus.includes(code) || msg?.includes('NoClassDefFoundError'))
      return this.serverUpdateErrorHandle<R>(status, responseData, _ctx)
    // 500
    if (this.errorStatus.includes(status) || this.errorStatus.includes(code) || msg?.includes('服务未找到'))
      return this.serverErrorHandle<R>(status, responseData, _ctx)

    return this.responseHandle<R>(responseData, _ctx)
  }

  /**
   * 响应错误拦截
   * statusCode 不是重定向并且大于 299时触发
   * @param _ctx NitroFetchContext
   */
  onResponseError = async <R = FetchResponseType<unknown>>(_ctx: NitroFetchContext & { response: FetchResponse<R> }): Promise<void> => {
    // 对响应错误进行处理
    // console.error('onResponseError', _ctx)
    const uuid = this.getHeaderUUID(_ctx.options.headers as Headers)
    const nuxtApp = uuid ? this.getCurrentNuxtApp(uuid) : null

    if (nuxtApp && uuid) {
      this.removeCurrentNuxtApp(uuid)
      const { removePendingRequest } = nuxtApp.$useAbort as ReturnType<typeof useAbort>
      removePendingRequest(_ctx, 'clear')
    }
  }

  setToken(headers: Headers) {
    const { USER_TOKEN } = storeToRefs(useAuth())
    // 设置token
    if (headers.has('Authorization') || headers.has('authorization'))
      USER_TOKEN.value = headers.get('Authorization') || headers.get('authorization') || ''
  }

  setClientHeaders(headers: HeadersInit | undefined): HeadersInit | undefined {
    if (!headers)
      return headers
    try {
      const reqHeader = useRequestHeaders([
        'x-real-ip',
        'x-forwarded-for',
        'x-forwarded-port',
        'x-forwarded-proto',
        'user-agent',
      ])
      headers = {
        ...headers,
        'x-real-ip': reqHeader['x-real-ip'] || '',
        'x-forwarded-for': reqHeader['x-forwarded-for'] || '',
        'x-forwarded-port': reqHeader['x-forwarded-port'] || '',
        'x-forwarded-proto': reqHeader['x-forwarded-proto'] || '',
        'user-agent': reqHeader['user-agent'] || 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/108.0.0.0 Safari/537.36',
      }
    }
    catch (e) {
      // console.error(e)
    }

    return headers
  }

  async responseHandle<R>(responseData: FetchResponseType<unknown>, _ctx: NitroFetchContext & { response: FetchResponse<R> }) {
    const { code, msg: _msg } = responseData

    if (this.successCode.includes(code))
      return responseData

    if (this.loginCode.includes(responseData.code)) {
      const authStore = useAuth()
      authStore.clearLogin()

      if (isClient) {
        useNotification.warning({
          content: responseData.msg || '还未登录，请先登录',
          duration: 3000,
        })
      }
    }

    if (responseData.code === 450) {
      // 撤帖评论数不符合要求
      return Promise.reject(responseData)
    }

    // 绑定手机(微信第一次登录，需要绑定手机)
    if (this.bindPhoneCode.includes(responseData.code)) {
      isClient && useEmit('bindPhone', responseData.data)

      return Promise.resolve(responseData)
    }
    return Promise.reject(new Error(`${isObject(responseData) ? JSON.stringify({ ...responseData, url: _ctx.request }) : responseData}`))
  }

  localErrorHandle<R>(_status: number, responseData: FetchResponseType<unknown>, _ctx: NitroFetchContext & { response: FetchResponse<R> }) {
    const { noPrompts = false } = _ctx.options.setting || {}
    !noPrompts && useNotification.warning({
      title: '错误',
      content: '网络请求超时',
      duration: 3000,
    })
    return Promise.reject(new Error(`${isObject(responseData) ? JSON.stringify({ ...responseData, url: _ctx.request }) : responseData}`))
  }

  timeoutErrorHandle(_ctx: NitroFetchContext) {
    const { noPrompts = false } = _ctx.options.setting || {}

    if (!noPrompts) {
      useMessage.destroyAll()
      useNotification.destroyAll()
      useNotification.error({
        title: '错误',
        content: '请求响应超时，请稍后再试',
        duration: 3000,
      })
    }
    return Promise.reject(createError({ cause: '网络请求超时', message: '请求响应超时，请稍后再试', data: `url：${_ctx.request}` }))
  }

  serverErrorHandle<R>(status: number, responseData: FetchResponseType<unknown>, _ctx: NitroFetchContext & { response: FetchResponse<R> }) {
    const { noPrompts = false } = _ctx.options.setting || {}
    if (!noPrompts) {
      useNotification.destroyAll()
      useNotification.error({
        title: '请求错误',
        content: getErrorMsg(_ctx.response._data) || '服务器发生了错误,请重试',
        duration: 3000,
      })
    }
    return Promise.reject(new Error(`${isObject(responseData) ? JSON.stringify({ ...responseData, url: _ctx.request }) : `${responseData}url:${_ctx.request}`}`))
  }

  serverUpdateErrorHandle<R>(status: number, responseData: FetchResponseType<unknown>, _ctx: NitroFetchContext & { response: FetchResponse<R> }) {
    const { noPrompts = false } = _ctx.options.setting || {}
    if (!noPrompts) {
      useNotification.destroyAll()
      useNotification.error({
        title: '服务器更新中',
        content: '服务更新中，请稍后再试',
      })
    }
    const error = createError({ cause: '服务器更新中', message: '服务更新中，请稍后再试', data: `${isObject(responseData) ? JSON.stringify({ ...responseData, url: _ctx.request }) : `${responseData}url:${_ctx.request}`}` })
    return Promise.reject(error)
  }
}
