import type {
  AccountLoginParams,
  CompliantChangeScoreParams,
  CompliantDatumParams,
  CompliantFeedbackParams,
  CompliantScoreParams,
  FeedbackMessageResponse,
  ForgetParams,
  MessageParams,
  MessageResponse,
  ModifyUserInfoParams,
  PhoneLoginParams,
  RegisterParams,
  ScanDrivingResponse,
  UndoComplaintResponse,
  UserAddAddressParams,
  UserAddressResponse,
  UserCarParams,
  UserCarResponse,
  UserComment,
  UserComplaintCollectionResponse,
  UserComplaintResponse,
  UserInfoResponse,
  UserInteGrationLogResponse,
  UserNewsCollectionResponse,
} from './types'
import type { FetchResponseType, PaginationParams, PaginationResponse } from '@/server/request/types'
import { useApiFetch } from '@/server/request'

/**
 * 验证账号是否被注册
 */
export async function checkAccountApi(params: { account: string }) {
  return useApiFetch<FetchResponseType<boolean>>('/webportal/auth/existAccount', {
    method: 'GET',
    params,
    setting: {
      repetition: 'pre',
    },
  })
}

/**
 * 微信扫码授权
 * @param params 重定向地址
 * @returns
 */
export async function getWxQrCode(params: { redirectUrl: string }) {
  return useApiFetch<FetchResponseType<string>>('/thirdparty/base/wx/open/qrcode', {
    method: 'GET',
    params,
  })
}

/**
 * 微信openCode 登陆
 * @param params
 * @returns
 */
export async function thirdpartyLoginApi(params: { clientTypeCode: string, loginCode: string }) {
  return useApiFetch<FetchResponseType<string | UserInfoResponse>>('/webportal/auth/thirdparty/login', {
    method: 'POST',
    params,
  })
}

/**
 * 微信openCode 登陆
 * @param params
 * @returns
 */
export async function phoneBindThirdpartyApi(params: { clientTypeCode: string, unionId: string, phone: string, verifycode: string }) {
  return useApiFetch<FetchResponseType<UserInfoResponse>>('webportal/auth/phoneBindThirdparty', {
    method: 'POST',
    params,
  })
}

/**
 * 验证手机号是否被注册
 */
export async function checkPhoneApi(params: { phone: string }) {
  return useApiFetch<FetchResponseType<string>>('/webportal/auth/existPhone', {
    method: 'GET',
    params,
    setting: {
      repetition: 'pre',
    },
  })
}

/**
 * 注册
 */
export async function registerApi(params: RegisterParams) {
  return useApiFetch<FetchResponseType<UserInfoResponse>>('/webportal/auth/register', {
    method: 'POST',
    body: params,
  })
}

/**
 * 忘记密码
 */
export async function forgetPasswordApi(params: ForgetParams) {
  return useApiFetch<FetchResponseType<UserInfoResponse>>('/webportal/auth/forgetPwd', {
    method: 'POST',
    params,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/**
 * 获取手机号验证码
 */
export async function getVerificationCodeApi(phone: string) {
  return useApiFetch<FetchResponseType<boolean>>('/webportal/auth/verifycode/send', {
    method: 'POST',
    params: {
      phone,
    },
  })
}

/**
 * 手机号登录
 */
export async function phoneLoginApi(params: PhoneLoginParams) {
  return useApiFetch<FetchResponseType<UserInfoResponse>>('/webportal/auth/verifycode/login', {
    method: 'POST',
    params,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/**
 * 账号登录
 */
export async function accountLoginApi(params: AccountLoginParams) {
  return useApiFetch<FetchResponseType<UserInfoResponse>>('/webportal/auth/login', {
    method: 'POST',
    params,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/**
 * 退出登录
 */
export async function logoutApi() {
  return useApiFetch<FetchResponseType<boolean>>('/webportal/portal/user/logout', {
    method: 'POST',
  })
}

export async function getUserInfoApi() {
  return useApiFetch<FetchResponseType<UserInfoResponse>>('/webportal/portal/user/find', {
    method: 'GET',
  })
}

/**
 * 获取通知消息列表
 */
export async function getNotificationsApi(params: MessageParams) {
  return useApiFetch<FetchResponseType<PaginationResponse<MessageResponse[]>>>('/webportal/messageNotify/page', {
    method: 'GET',
    params,
  })
}
/**
 * 设置通知消息已读
 */
export async function readNotificationApi(id: string) {
  return useApiFetch<FetchResponseType<PaginationResponse<MessageResponse[]>>>(`/webportal/messageNotify/readEnd/${id}`, {
    method: 'POST',
  })
}

/**
 * 获取反馈消息列表
 */
export async function getFeedbackListApi(params: PaginationParams) {
  return useApiFetch<FetchResponseType<PaginationResponse<FeedbackMessageResponse[]>>>('/webportal/user/suggest/page', {
    method: 'GET',
    params,
  })
}

/**
 *  是否关注公众号
 */
export async function checkUserFocusMpApi() {
  return useApiFetch<FetchResponseType<boolean>>('/webportal/portal/user/isSubscribeWxMp', {
    method: 'GET',
  })
}
/**
 * 生成用户专属公众号关注二维码
 */
export async function generateMpQrcodeApi(userId: string) {
  return useApiFetch<FetchResponseType<string>>('/thirdparty/base/wx/wxMp/qrCodeUrl', {
    method: 'GET',
    params: { userId },
  })
}

export async function modifyUserInfoApi(params: ModifyUserInfoParams) {
  return useApiFetch<FetchResponseType<UserInfoResponse>>('/webportal/portal/user/update', {
    method: 'POST',
    body: params,
  })
}

export async function modifyAccountApi(params: { newAccount: string }) {
  return useApiFetch<FetchResponseType<UserInfoResponse>>('/webportal/portal/user/update/loginAccount', {
    method: 'POST',
    params,
  })
}

export async function modifyPhoneApi(params: { newPhone: string, newPhoneVerifycode: string }) {
  return useApiFetch<FetchResponseType<UserInfoResponse>>('/webportal/portal/user/update/loginPhone', {
    method: 'POST',
    params,
  })
}

export async function modifyPasswordApi(params: { newPwd1: string, newPwd2: string }) {
  return useApiFetch<FetchResponseType<UserInfoResponse>>('/webportal/portal/user/update/loginPwd', {
    method: 'POST',
    params,
  })
}

export async function addUserAddressApi(params: UserAddAddressParams) {
  return useApiFetch<FetchResponseType<UserAddressResponse>>('/webportal/user/address/create', {
    method: 'POST',
    body: params,
  })
}
export async function updateUserAddressApi(params: UserAddAddressParams & { id: number }) {
  return useApiFetch<FetchResponseType<UserAddressResponse>>('/webportal/user/address/update', {
    method: 'POST',
    body: params,
  })
}
export async function deleteUserAddressApi(id: string | number) {
  return useApiFetch<FetchResponseType<boolean>>(`/webportal/user/address/del/${id}`, {
    method: 'POST',
  })
}
export async function getUserAddressDetailApi(id: string) {
  return useApiFetch<FetchResponseType<UserAddressResponse>>(`/webportal/user/address/find/${id}`, {
    method: 'GET',
  })
}
export async function getUserAddressPageApi(params: PaginationParams) {
  return useApiFetch<FetchResponseType<PaginationResponse<UserAddressResponse[]>>>('/webportal/user/address/page', {
    method: 'GET',
    params,
  })
}

export async function getUserCommentApi(params: PaginationParams & { queryCommentType: 1 | 2 }) {
  return useApiFetch<FetchResponseType<PaginationResponse<UserComment[]>>>('/webportal/user/comment/complainPage', {
    method: 'GET',
    params,
  })
}

export async function getUserIntegrationLogApi(params: PaginationParams) {
  return useApiFetch<FetchResponseType<PaginationResponse<UserInteGrationLogResponse[]>>>('/webportal/portal/user/integralInfoPage', {
    method: 'GET',
    params,
  })
}

export async function getUserNewsCollectionApi(params: PaginationParams) {
  return useApiFetch<FetchResponseType<PaginationResponse<UserNewsCollectionResponse[]>>>('/webportal/portal/user/articleCollectPage', {
    method: 'GET',
    params,
  })
}

export async function getUserComplaintCollectionApi(params: PaginationParams) {
  return useApiFetch<FetchResponseType<PaginationResponse<UserComplaintCollectionResponse[]>>>('/webportal/portal/user/complainCollectPage', {
    method: 'GET',
    params,
  })
}

export async function getUserCarPageApi(params: PaginationParams) {
  return useApiFetch<FetchResponseType<PaginationResponse<UserCarResponse[]>>>('/webportal/car/driving/page', {
    method: 'GET',
    params,
  })
}

export async function getUserScanDrivingApi(picture: string) {
  return useApiFetch<FetchResponseType<ScanDrivingResponse>>(`/webportal/ai/vin`, {
    method: 'POST',
    params: { vinImgUrl: picture },
  })
}

export async function getUserCarDetailApi(carDrivingId?: string) {
  return useApiFetch<FetchResponseType<UserCarResponse>>(`/webportal/car/driving/findById`, {
    method: 'GET',
    params: { carDrivingId },
  })
}

export async function addUserCarApi(params: UserCarParams, setting: { noPrompts?: boolean } = {}) {
  return useApiFetch<FetchResponseType<UserCarResponse>>(`/webportal/car/driving/create`, {
    method: 'POST',
    body: params,
    setting: {
      ...setting,
    },
  })
}

export async function updateUserCarApi(params: Partial<UserCarParams>) {
  return useApiFetch<FetchResponseType<UserCarResponse>>(`/webportal/car/driving/update`, {
    method: 'POST',
    body: params,
  })
}

export async function deleteUserCarApi(id: string) {
  return useApiFetch<FetchResponseType<boolean>>(`/webportal/car/driving/del/${id}`, {
    method: 'POST',
  })
}

export async function getUserComplaintPageApi(params: PaginationParams) {
  return useApiFetch<FetchResponseType<PaginationResponse<UserComplaintResponse[]>>>('/webportal/portal/user/complainPage', {
    method: 'GET',
    params,
  })
}

export async function undoComplaintApi(seriesId?: string | number) {
  return useApiFetch<FetchResponseType<UndoComplaintResponse[]>>('/webportal/complain/undoRecom', {
    method: 'GET',
    params: seriesId ? { carSeriesId: seriesId } : {},
  })
}

/**
 * 申请加入集体投诉
 */
export async function complainApplyCollectiveApi(id: number | string, params: { collectiveId: number | string }) {
  return useApiFetch<FetchResponseType<boolean>>(`/webportal/complain/applyCollective/${id}`, {
    method: 'POST',
    params,
  })
}

/**
 * 申请一追到底
 */
export async function complainApplySurveyBottomApi(id: number | string) {
  return useApiFetch<FetchResponseType<boolean>>(`/webportal/complain/applySurveyBottom/${id}`, {
    method: 'POST',
    setting: {
      noPrompts: true, // 关闭拦截器自动提示
    },
  })
}
/**
 * 申请加急
 */
export async function complainUrgentApi(id: number | string) {
  return useApiFetch<FetchResponseType<boolean>>(`/webportal/complainUrgent/${id}`, {
    method: 'POST',
    setting: {
      noPrompts: true, // 关闭拦截器自动提示
    },
  })
}

/**
 * 投诉打分修改
 */
export async function compliantChangeScoreApi(params: CompliantChangeScoreParams) {
  return useApiFetch<FetchResponseType<boolean>>(`/webportal/portal/user/updComplainScore`, {
    method: 'POST',
    params,
    setting: {
      noPrompts: true, // 关闭拦截器自动提示
    },
  })
}

/**
 * 投诉补充
 */
export async function compliantDatumApi(params: CompliantDatumParams) {
  return useApiFetch<FetchResponseType<boolean>>(`/webportal/complain/datum`, {
    method: 'POST',
    body: params,
    setting: {
      noPrompts: true, // 关闭拦截器自动提示
    },
  })
}

/**
 * 投诉反馈
 */
export async function compliantFeedbackApi(params: CompliantFeedbackParams) {
  return useApiFetch<FetchResponseType<boolean>>(`/webportal/complain/feedback`, {
    method: 'POST',
    body: params,
    setting: {
      noPrompts: true, // 关闭拦截器自动提示
    },
  })
}

/**
 * 投诉打分
 */
export async function compliantScoreApi(id: string | number, params: CompliantScoreParams) {
  return useApiFetch<FetchResponseType<boolean>>(`/webportal/complain/score/${id}`, {
    method: 'POST',
    params,
    setting: {
      noPrompts: true, // 关闭拦截器自动提示
    },
  })
}

/**
 * 撤贴投诉
 */
export async function compliantWithdrawApi(id: string | number) {
  return useApiFetch<FetchResponseType<boolean>>(`/webportal/complain/backout/${id}`, {
    method: 'POST',
    setting: {
      noPrompts: true, // 关闭拦截器自动提示
    },
  })
}

/**
 * 撤贴投诉
 */
export async function feedbackApi(params: any) {
  return useApiFetch<FetchResponseType<boolean>>(`/webportal/user/suggest`, {
    method: 'POST',
    setting: {
      noPrompts: true, // 关闭拦截器自动提示
    },
    body: params,
  })
}

/**
 * 平台访问
 */
export async function userAddAccessApi() {
  return useApiFetch<FetchResponseType<boolean>>(`/webportal/base/portal/addAccess`, {
    method: 'POST',
    setting: {
      noPrompts: true, // 关闭拦截器自动提示
    },

  })
}
