// composables/useEventBus.ts
import mitt from 'mitt'

// 为 mitt 方法里的’modify-user‘事件标注类型

const emitter = mitt()

export const useEmit = emitter.emit // 触发事件方法 $emits
export const useOn = emitter.on // 监听事件方法 $on

export const useAllServiceEventBus = () => useState('useAllServiceEventBus', () => ref({}))
