import { LRUCache as LRU_CACHE } from 'lru-cache'
import type { RequestControllerKey } from './types'

export const CacheOption = {
  max: 100,
  // how long to live in ms
  ttl: 1000 * 60 * 5,
  allowStale: true,
}
export class CacheModule {
  cacheInstance
  NEED_CACHE_API: string[] = [
    '/webportal/base/help',
    '/webportal/base/ranking',
    '/webportal/messageNotify/page',
    '/webportal/user/suggest/page',
    '/webportal/base/complainPublish/brandLs',
    '/webportal/base/complainPublish/seriesLs',
    '/webportal/base/complainPublish/modelLs',
    '/webportal/base/wom/seriesIssueStatTopPage',
    '/webportal/base/search/complain',
    '/webportal/base/complainPublish/partsLs',
    '/webportal/base/complainPublish/issueLs',
    '/webportal/base/series/all',
  ]

  NEED_CACHE_KEYS: string[] = []

  EXCLUDE_KEYS: string[] = []

  constructor(options: LRU_CACHE.Options<RequestControllerKey, Promise<unknown>, unknown>) {
    this.cacheInstance = new LRU_CACHE(options)
  }

  // 判断是否需要缓存
  checkNeedCache(api: string, key: RequestControllerKey, _log = false) {
    let isNeed = false
    if (api.match(/\/\d+$/))
      isNeed = this.NEED_CACHE_API.includes(api.replace(/\/\d+$/, ''))

    const isNeedCache = isNeed || (this.NEED_CACHE_API.includes(api) && !this.EXCLUDE_KEYS.includes(key)) || this.NEED_CACHE_KEYS.includes(key)
    return isNeedCache
  }

  fetch(key: RequestControllerKey) {
    return this.cacheInstance.fetch(key)
  }
}
