<!--
 * @Author: fengjiahua fenjiahua@qq.com
 * @Date: 2024-01-24 10:05:36
 * @LastEditors: fengjiahua fenjiahua@qq.com
 * @LastEditTime: 2024-07-12 09:30:30
-->
<script setup lang="ts">
import { dateZhCN, zhCN } from 'naive-ui'

import { addLogApi } from './server/modules/log'
import type { UserInfoResponse } from './server/types'

import { LOAD_STATE } from './shared/types'
import { HOME_SITE_META } from '@/shared/site'
import { useTheme } from '@/shared/theme'
import adStore from '@/stores/ad'
import { getAllCarSeriesApi, thirdpartyLoginApi, userAddAccessApi } from '~/server'

const store = adStore()
store.getAdData()
const { naiveTheme, naiveThemeOverrides } = useTheme()
useSite(HOME_SITE_META)
process.client && userAddAccessApi()

process.client && window.addEventListener('unhandledrejection', (e) => {
  const url = window.location.href || ''
  const host = window.location.host
  const errorMsg = e.reason?.message || ''
  console.log('🚀 ~ process.client&&window.addEventListener ~ e:', e)
  if (

    e.reason.message?.includes('/base/error/portalAppear')
    || url.includes('.html')
    || errorMsg.includes('.finally is not a function')
    || errorMsg.includes('页面切换终止请求')
    || errorMsg.includes('aborted a request')
    || errorMsg.includes('Failed to fetch')
    || errorMsg.includes('登录令牌已过期')
  )
    return

  try {
    addLogApi({
      data: e.reason?.data || '',
      code: e.reason?.status || '',
      url,
      errorMsg,
      remark: e.reason?.stack || '',
    })
  }
  catch (e) {
    console.log('🚀 ~ process.client&&window.addEventListener ~ e:', e)
  }

  // console.log('🚀 ~ process.client&&window.addEventListener ~ e:', e)
})

// const state = ref(LOAD_STATE.LOADING)
// useLoadState({
//   fn: () => getAllCarSeriesApi(),
//   key: 'getAllCarSeriesApi',
//   state,
// })

// useFetch('/webportal/base/series/all', {
//   key: 'getAllCarSeriesApi',
//   baseURL: 'https://www.qctsw.com/api',
// })

// useAllServiceEventBus().value = await getAllCarSeriesApi()

const wxCode = useRoute().query?.code
onMounted(() => {
  if (wxCode && !useAuth().IS_LOGIN) {
    thirdpartyLoginApi({ loginCode: wxCode as string, clientTypeCode: 'WX_OPEN' }).then((res) => {
      if (res.code === 201) {
        const authStore = useAuth()
        authStore.setUserInfo(res.data as UserInfoResponse)
        useNotification.success({
          title: '提示',
          content: '登录成功，欢迎来到汽车投诉网',
          duration: 3000,
          closable: true,
        })
      }
    })
  }
})

// process.client && Promise.reject(createError({ data: '1', message: '2', cause: '3' }))
</script>

<template>
  <div>
    <n-config-provider
      :locale="zhCN" :date-locale="dateZhCN"
      :theme="naiveTheme" :theme-overrides="naiveThemeOverrides"
      inline-theme-disabled
    >
      <base-feedback />
      <base-mobile />
      <NuxtLayout>
        <NuxtPage :keepalive="{ max: 5, exclude: [/\/user\/.*/, 'publish'] }" />
      </NuxtLayout>
    </n-config-provider>
  </div>
</template>

<style lang="scss">
html {
  font-size: 16px;
}

.image-error {
  position: relative;
}

.n-image {
  img {
    --uno: w-full;
  }
}
.n-image-preview-toolbar .n-base-icon:nth-child(6){
  display: none;
}

img {
  --uno: bg-slate-1;
}

img.image-error::before {
  --uno: content-empty absolute top-0 left-0 right-0 bottom-0;
  --uno: bg-slate-1;
}

img.image-error::after {
  --uno: content-empty absolute top-1/2 left-1/2 -translate-1/2 right-0 bottom-0;
  --uno: bg-slate-3 i-ic:baseline-broken-image;
}

#__nuxt,
html,
body {
  --uno: m-0 p-0 break-all;
}

body {
  --uno: text-4;
}

#nprogress .bar {
  --uno: bg-blue-5 text-3.5 w-full h-1;
  --uno: shadow-[0_2px_4px_0_#3b82f6];
  // ??打包后没有 top-[calc(3.75rem+2em)]
  --uno: fixed top-0 top-[calc(3.75rem+2em)] left-0 right-0 z-999;
}

a:focus:focus-visible,
button:focus:focus-visible {
  --uno: outline outline-2 outline-blue-5;
}
</style>
