/*
 * @Author: fengjiahua fenjiahua@qq.com
 * @Date: 2024-02-21 10:19:08
 * @LastEditors: fengjiahua fenjiahua@qq.com
 * @LastEditTime: 2024-04-07 15:18:45
 * @FilePath: \qctsw-vue\packages\app\src\middleware\navigatePhone.global.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import { BASE_DOMAIN, BASE_HOST } from '@qctsw/common'

const pcToPhonePath: [RegExp, string][] = [

  [/\/news\/detail\/(\d+)/, '/h5/subpages/pages/index/detail?data_id='],
  [/\/complaint\/detail\/(\d+)/, '/h5/subpages/pages/complaint/detail?data_id='],
  [/\/complaint\/collective\/(\d+)/, '/h5/subpages/pages/complaint/detail-collective?data_id='],
  [/\/topic\/(\d+)/, '/h5/topic/index?data_id='],
  [/\/list\/red-black/, '/h5/subpages/pages/index/red_black_list'],
  [/\/news\/author\/(\d+)/, '/h5/subpages/pages/index/author?data_id='],
  [/\/news\/media\/(\d+)/, '/h5/subpages/pages/index/media?data_id='],
  [/\/news/, '/h5'],
  [/\/complaint/, '/h5/pages/complaint/index'],
  [/\//, '/h5'],
]

// 移动端访问pc链接 重定向phone链接
export default defineNuxtRouteMiddleware((to) => {
  const { isMobile } = useDevice()
  if (isMobile && process.client) {
    const { path } = to

    for (const item of pcToPhonePath) {
      const pcPath = item[0]

      if (pcPath.test(path)) {
        const match = path.match(pcPath)
        if (match && match?.[1]) {
          window.location.href = `https://www.qctsw.com${item[1]}${match[1]}`
          // console.log('🚀 ~ defineNuxtRouteMiddleware ~ match:', `http://qctsw.com${item[1]}${match[1]}`)
          break
        }

        window.location.href = `https://www.qctsw.com${item[1]}`
        // console.log('跳转手机端', `http://qctsw.com${item[1]}`)
        break
      }
    }
  }
})
