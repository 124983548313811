/*
 * @Author: fengjiahua fenjiahua@qq.com
 * @Date: 2024-01-24 10:05:36
 * @LastEditors: fengjiahua fenjiahua@qq.com
 * @LastEditTime: 2024-02-23 15:52:11
 */
// eslint-disable-next-line node/prefer-global/process
const isDev = process.env.NODE_ENV === 'development'

export const SITE_NAME = '汽车投诉网'
export const COOKIE_BASE_DOMAIN = isDev ? 'localhost' : '.qctsw.com'
export const BASE_DOMAIN = isDev ? 'localhost' : 'qctsw.com'
export const BASE_HOST = isDev ? `http://${BASE_DOMAIN}` : (typeof window !== 'undefined' ? window.location.origin : `https://www.${BASE_DOMAIN}`)

export const BASE_HOST_APP = isDev ? `${BASE_HOST}:4123` : BASE_HOST
export const BASE_HOST_COMPANY = isDev ? `${BASE_HOST}:5555` : `${BASE_HOST}`

/**
 * 资源文件前缀
 */
export const FILE_PRE_HOST_REGEXP = /http(s)?:\/\/qcts\.(gz|cdn)\.bcebos\.com/
export const FILE_PRE_HOST = 'https://qcts.cdn.bcebos.com'

export const FILE_GZ_HOST = 'https://qcts.gz.bcebos.com'
export const IMG_SIZE_REG = '?x-bce-process=image/resize,m_fill,w_{width},h_{height}'
export const IMG_WATER_MARK_REG = '/watermark,color_e9e9e9,type_RlpMYW5UaW5nSGVpU0RCMUdC,size_16,g_9,x_20,y_20,text_{watermark}'

/**
 * 上传文件类型
 */
export const UPLOAD_IMAGE_TYPE = 'image/png,image/jpeg,image/webp'
