/**
 * 给图片添加域名
 */
import { createRegExp, isString } from '@qctsw/utils'
import { FILE_PRE_HOST, FILE_PRE_HOST_REGEXP, IMG_WATER_MARK_REG } from '../constants'

export function addHost(src?: string, host = FILE_PRE_HOST): string {
  if (src && typeof src == 'string' && !src.match(/http/) && !src.match(/(ws|rtmp)/) && !src.match(/^data:.*;base64.*/)) {
    src = host + src
    return src
  }
  return src || ''
}

/**
 * 移除文件资源路径前缀域名
 * @param src string
 * @returns string
 */
export function removeHost(str: string, host = FILE_PRE_HOST) {
  if (!str || str.match(/^data:.*;base64.*/) || !isString(str))
    return str
  if (host !== FILE_PRE_HOST)
    return str.replace(new RegExp(host, 'gi'), '')

  let newStr = str.replace(FILE_PRE_HOST_REGEXP, '')
  newStr = newStr.split('?x-bce-process=image')[0]
  return newStr
}

export function imageWater(src = '', waterMark?: string | null) {
  if (src.includes('/watermark') || !waterMark)
    return src

  return src + IMG_WATER_MARK_REG.replace('{watermark}', waterMark)
}

export function imageFormat(src = '', format: 'png' | 'jpeg' | 'webp' = 'jpeg') {
  if (!src || /\/format,f_(png|jpeg|webp)/.test(src))
    return src
  return `${src}/format,f_${format}`
}

export function imageSize(src = '', options: {
  width: number
  height: number
  type?: 'lfit' | 'fill'
}) {
  if (!src || /\/resize/.test(src))
    return src

  const { width, height, type = 'fill' } = options
  return `${src}/resize,m_${type},w_${width},h_${height}`
}

//  https://qcts-qcwp.gz.bcebos.com/qcts-qcwp/qcts/complain/img/202204/2ede607e8e3f416682d81a1fb0c46664.png?x-bce-process=image/resize,m_lfit,w_1100,h_500
export function imageTransform(src: string | null = '', option?: {
  width?: number
  height?: number
  type?: 'lfit' | 'fill'
  format?: 'png' | 'jpeg' | 'webp'
  waterMark?: string | null
}) {
  const { width, height, type, format = 'jpeg', waterMark } = option || {}
  if (
    !src
    || !isString(src)
    || src.match(/^data:.*;base64.*/)
    || src.match(/(ws|rtmp)/)
    || (src.match(/^http/) && !src.match(/(qcts|qcwp|qcts-qcwp).(gz|cdn).bcebos.com/))
  )
    return src || ''

  let _src = src

  if (src.match(/^\//))
    _src = addHost(src)

  const prefix = '?x-bce-process=image'
  if (!src.includes(prefix))
    _src += prefix

  if (format)
    _src = imageFormat(_src, format)

  if (width && height)
    _src = imageSize(_src, { width, height, type })

  if (waterMark)
    _src = imageWater(_src, waterMark)

  return _src
}

/**
 * 给富文本字符串中的图片标签src加上域名地址
 * @param str string
 * @param host string  default:FILE_PRE_HOST
 * @returns string
 */
export function contentAddHost(str: string, host = FILE_PRE_HOST) {
  // str = str ? str : '<P><img src="http://bbs.cn.yimg.com/user_img/200701/31/jisuanji986_117025184198149.jpg"><img src="https://bbs.cn.yimg.com/user_img/200701/31/jisuanji986_117025184198149.jpg"><img src="/200701/31/jisuanji986_117025184198149.jpg"><img src="/jisuanji986_117025184198149.jpg"></P>';
  // a 为富文本的字符串内容，为了测试，只写了img标签
  const b = /<(?:img|video|audio) [^>]*src=['"]([^'"]+)[^>]*>/g// img 标签取src里面内容的正则
  const s = str.match(b)// 取到所有img标签 放到数组 s里面
  if (!s)
    return str

  const srcSource = new Set<string>()
  for (let i = 0; i < s.length; i++) {
    const srcImg = s[i].replace(b, '$1')// 取src面的内容
    // 若src前4位置或者前5位是http、https则不做任何修改,或者是base64
    // console.log('不做任何修改');
    if (srcImg.match(/^http/) || srcImg.match(/^data:.*;base64.*/))
      continue
    else
      srcSource.add(srcImg)
  }
  srcSource.forEach((src) => {
    // 修改富文本字符串内容 img标签src 相对路径改为绝对路径
    const newSrc = (host && host !== FILE_PRE_HOST) ? host + src : imageTransform(src, { })
    str = str.replace(createRegExp(src, 'g'), newSrc)
  })
  return str
}

/**
 * 移除字符串中的资源拼接地址
 * @param str string
 * @param host string  default:FILE_PRE_HOST
 * @returns string
 */
export function contentRemoveHost(str: string, host = FILE_PRE_HOST) {
  if (!str)
    return str

  const images = getContentImages(str)
  if (!images.length)
    return str

  for (const item of images) {
    const newSrc = removeHost(item, host)
    str = str.replace(createRegExp(item, 'g'), newSrc)
  }
  return str
}

export function lazyImages(str: string) {
  const b = /<img/g
  str = str.replace(b, '<img loading="lazy"')

  return str
}

/**
 * 获取富文本字符串中的图片
 * @param str string
 * @param host string  default:FILE_PRE_HOST
 * @returns string
 */
export function getContentImages(str: string) {
  if (!str)
    return []

  // str = str ? str : '<P><img src="http://bbs.cn.yimg.com/user_img/200701/31/jisuanji986_117025184198149.jpg"><img src="https://bbs.cn.yimg.com/user_img/200701/31/jisuanji986_117025184198149.jpg"><img src="/200701/31/jisuanji986_117025184198149.jpg"><img src="/jisuanji986_117025184198149.jpg"></P>';
  // a 为富文本的字符串内容，为了测试，只写了img标签
  const b = /<img [^>]*src=['"]([^'"]+)[^>]*>/g// img 标签取src里面内容的正则
  const s = str.match(b)// 取到所有img标签 放到数组 s里面
  if (!s)
    return []

  const images = []
  for (let i = 0; i < s.length; i++) {
    const srcImg = s[i].replace(b, '$1')// 取src面的内容
    if (srcImg && !srcImg.match(/^data:.*;base64.*/))
      images.push(srcImg)
  }
  return images
}

export function contentRemoveStyle(str: string): string {
  const removeRegExps = [
    [/font-size\:(.*?);/gi, ''],
    [/font-family\:(.*?);/gi, ''],
  ] as const
  for (const item of removeRegExps)
    str = str.replace(item[0], item[1])

  return str
}
