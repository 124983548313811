import { useApiFetch } from '@/server/request'

export async function addLogApi(data: {
  code: string
  data: string
  errorMsg: string
  url: string
  remark: string
}) {
  return useApiFetch('/webportal/base/error/portalAppear', {
    method: 'post',
    body: data,
  })
}
