import { LOGIN_POPUP } from '@/shared/event'

export function useLoginPopupEvent() {
  const bus = useEventBus<{ show: boolean, type: 'phone' | 'account' }>(LOGIN_POPUP)

  const showLoginPopup = (type: 'phone' | 'account') => {
    bus.emit({ show: true, type })
  }
  const hideLoginPopup = () => {
    bus.emit({ show: false, type: 'phone' })
  }

  return {
    showLoginPopup,
    hideLoginPopup,
    bus,

  }
}
