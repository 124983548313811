<!--
 * @Author: fengjiahua fenjiahua@qq.com
 * @Date: 2024-01-26 09:17:12
 * @LastEditors: fengjiahua fenjiahua@qq.com
 * @LastEditTime: 2024-01-26 10:40:25
-->
<script lang='ts' setup>
import { NFormItem } from 'naive-ui'
import { feedbackApi } from '@/server'
import { CLIENT_TYPE_CODE_CONST } from '~/shared/constants'

const { IS_LOGIN } = storeToRefs(useAuth())

const isShowFeedBackDialog = ref(false)
const loading = ref(false)
const formData = ref({
  content: '',
  imgs: [],
  clientTypeCode: CLIENT_TYPE_CODE_CONST,
})
const rules = {}
const formRef = ref()
function showFeedBackDialog() {
  if (!IS_LOGIN.value)
    return useAuth().needLoginHandle()
  isShowFeedBackDialog.value = true
}

useOn('feedback', () => {
  showFeedBackDialog()
})

async function submitForm() {
  try {
    loading.value = true
    await feedbackApi(formData.value)
    useMessage.success('反馈提交成功')
    isShowFeedBackDialog.value = false
  }
  finally {
    loading.value = false
  }
}
</script>

<template>
  <div class="customer flex flex-col pt-4 bg-blue-5" @click="showFeedBackDialog">
    <i class="i-ep:headset customer-icon" />
    <div class="customer-container">
      意见反馈
    </div>
  </div>
  <n-modal v-model:show="isShowFeedBackDialog" mask-closable>
    <n-card
      style="width: 600px"
      title="问题/意见反馈"
      :bordered="false"
      size="huge"
      role="dialog"
      aria-modal="true"
    >
      <NForm
        ref="formRef"
        :model="formData"
        :rules="rules"
        size="large"
      >
        <NFormItem path="phone" :show-label="false">
          <NInput
            v-model:value="formData.content"
            type="textarea"

            size="large"
            placeholder="请留下您的反馈..."
          />
        </NFormItem>
        <NFormItem path="fileList" label="上传图片">
          <Basic-Upload v-model="formData.imgs" :max="5" />
        </NFormItem>

        <NFormItem :show-label="false">
          <div flex w-full justify-center>
            <NButton :loading="loading" w-30 type="primary" @click="submitForm">
              提交
            </NButton>
          </div>
        </NFormItem>
      </NForm>
    </n-card>
  </n-modal>
</template>

<style lang='scss' scoped>
.customer {
  position: fixed;
  bottom: 250px;
  right: 0;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
  z-index: 5;
  color: #fff;
  border-radius: 6px 0 0 6px;
  font-weight: bold;
  z-index: 12;

  &-icon {
    font-size: 18px;
  }

  &-container {
    width: 40px;
    padding: 5px 0 16px 0;
    line-height: 40px;
    border-radius: 6px;
    overflow: hidden;
    writing-mode: vertical-lr;
    letter-spacing: 1px;
  }
}
</style>
