/*
 * @Author: fengjiahua fenjiahua@qq.com
 * @Date: 2024-05-21 16:02:42
 * @LastEditors: fengjiahua fenjiahua@qq.com
 * @LastEditTime: 2024-10-28 14:16:45
 * @FilePath: \qctsw-vue\packages\app\src\plugins\abort.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { isClient } from '@qctsw/utils'
import _ from 'lodash'
import { useAbort } from '@/server/request/abort'
import { useAuth } from '@/stores/auth'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.$useAbort = useAbort()
  const { pendingRequest, abortPrePageKeys, clearPendingRequest } = nuxtApp.$useAbort as ReturnType<typeof useAbort>
  addRouteMiddleware('global-abort', (_to, _from) => {
    // clear abort request
    const extendsRoute: string[] = []
    const isExtendsPage = extendsRoute.includes(_to.name as string) || extendsRoute.includes(_from.name as string)
    if (pendingRequest.size > 0 && !isExtendsPage) {
      // eslint-disable-next-line no-console
      console.log(
        `%c[request abort because route change] %c pending request count: ${pendingRequest.size}`,
        'background: #14b8a6; padding: 2px 4px; border-radius: 3px 0 0 3px',
        'background: #99f6e4; padding: 2px 4px; border-radius: 0 3px 3px 0;',
      )
      pendingRequest.forEach((value: any, key: any) => {
        (value.controller as AbortController).abort(new Error('页面切换终止请求'))
        abortPrePageKeys.push(key)
        clearPendingRequest(key, 'clear')
      })
      pendingRequest.clear()
    }
  }, { global: true })

  // publish 投诉发表页 页面跳转
  addRouteMiddleware('publish-abort', async (_to, _from) => {
    // console.log('🚀 ~ addRouteMiddleware ~ _to, _from:', _to, _from)
    const { IS_LOGIN, IS_SUBMIT } = storeToRefs(useAuth())
    if (process.client && _from.name === 'publish' && _to.name !== 'publish' && !_to?.query?.navigate && IS_LOGIN.value && !IS_SUBMIT.value) {
      useDialog.warning({
        title: '提示',
        content: '您的投诉暂未提交，确定要离开吗？',
        positiveText: '确定',
        onPositiveClick: () => {
          navigateTo({
            name: _to.name as string,
            query: {
              navigate: 1,
            },

          })
        },
        onNegativeClick: () => {
          abortNavigation()
        },

      })
      return abortNavigation()
    }
  }, {
    global: true,
  })
})
